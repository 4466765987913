@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

* {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #151617;
}

#root {
  display: flex;
  justify-content: center;
  align-items: center;
}

body {
  height: 100vh;
  width: 100vw;
}

input {
  -webkit-appearance: none;
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
}