html {
    font-size: 12px;
}

.App-container {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    width: 100%;
    padding: 0 25px;
    gap: 40px;
}

@media (min-width: 900px) {
    html {
        font-size: 16px;
    }

    .App-container {
        font-size: 16px;
        margin-top: 100px;
        max-width: 750px;
        width: 750px;
        padding: 0;
    }
}